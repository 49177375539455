<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ resourcesList.length }}) Advert Videos</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="10" class="text-xs font-weight-semibold mt-1" color="info darken-2"
        to="/savings/marketing/add/resources/Video">
        <font-awesome-icon icon="fa-solid fa-plus" class="pe-1" />
        Add New Video
      </v-btn>

    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="resourcesList"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.resource_name`]="{item}">
        <div class="d-flex flex-column" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.resource_name }}</span>
          <small>{{ item.resource_description | truncate(50, '...') }}</small>
        </div>
      </template>
      <template #[`item.resource_size`]="{item}">
        {{ item.resource_size }}
      </template>
      <template #[`item.get_file`]="{item}">
        <a :href="item.get_file" target="_blank">
          <v-btn class="primary" elevation="6" icon small>
            <font-awesome-icon icon="fa-solid fa-download"/>
          </v-btn>
        </a>
      </template>
      <template #[`item.get_date_str`]="{item}">
        {{ item.get_date_str }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Pending: 'primary',
      Validated: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'resource_name' },
        { text: 'Uploader', value: 'uploader' },
        { text: 'Video Size', value: 'resource_size' },
        { text: 'Download', value: 'get_file' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      resourcesList: [],
      statusColor,
      search: '',

      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
 
  mounted(){
      // executes these after the page has been mounted
      this.getAdResources();
      document.title = "TopUp | List of Ad Resources"
  },

  methods:{
    getAdResources(){
      axios
        .get('/api/v1/topup/get/ad_resources/Video/')
        .then(response => {
          this.resourcesList = response.data  // get the data and fill into resources 
          console.log(this.resourcesList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getMoreInfo(info_id){
      this.$store.commit('setDialogTitle', "Getting More Information for "+info_id)
      this.$store.commit('setDialogMessage', "Your information has been collected. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months. <ul><li>This is one</li><li>This is two</li><li>This is three</li><li>This is four</li></ul>")
      this.$store.commit('activateDialog', true)
    }
  },
}
</script>
